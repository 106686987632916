import styled from 'styled-components';

export const ApplId = styled.section`
  display: flex;  
  flex-direction: column;

  span{
    font-size: 18px;
  }

  .button_submit{
    max-width: 230px;
    padding: 7px 0;
    font-size: 16px;
    background-color: ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textLigth};
    margin-top: 25px;
    border: 0;
    cursor: pointer;
  }
`;

export const ApplDowload = styled.div`
  button{
    margin-left: 15px;
    font-size: 16px;
    padding: 5px 15px;
    border: 0;
    cursor: pointer;
    background-color: ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textLigth};
  }
`;

export const ApplProgress = styled.div`
  margin: 10px 0;

  input{
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;

export const ApplTable = styled.table`
  width: 100%;
  margin-top: 20px;
  border: 1px solid ${(props) => props.theme.textDark};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.backgroundHover};

  tbody tr:nth-child(odd){
    background-color: ${(props) => props.theme.background};
  }
  border: 1px solid ${(props) => props.theme.textDark};

  tr, td, th{
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 16px;
  }

  th{
    padding: 10px;
    font-weight: 600;
    text-align: center;
  }

  td{
    padding: 10px 7px;
    text-align: center;
    font-weight: 400;
  }
`;

export const ApplOrder = styled.tr`
  text-align: center;

  button, input{
    cursor: pointer;
  }
`;
