import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { doc, updateDoc } from 'firebase/firestore';
import * as styles from './style';
import usePeople from '../../store/People';
import { db } from '../../service/firebaseConnection';

export default function CnpjEdit({ func, uid }) {
  const [thisCompany, setThisCompany] = useState([]);
  const [access, setAccess] = useState();
  const { company, setCompany } = usePeople();

  useEffect(() => {
    const c = company.filter((comp) => comp.uid === uid);

    setThisCompany(c);
    setAccess(c[0].access);
  }, [company]);

  const handleUpdate = async () => {
    try {
      const docRef = doc(db, 'users', uid);
      await updateDoc(docRef, { access });

      thisCompany[0].access = access;
      const c = company.filter((comp) => comp.uid !== uid);

      setCompany([...c, ...thisCompany]);
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Editar o Cnpj</h2>

        <styles.OrderList>
          {thisCompany.map((comp) => (
            <>
              <li>
                Cnpj:
                <b>{comp.cnpj}</b>
              </li>
              <li>
                Nome da empresa:
                <b>{comp.name}</b>
              </li>
              <li>
                Email:
                <b>{comp.email}</b>
              </li>
              <li>
                Telefone:
                <b>{comp.tel}</b>
              </li>
              <li>
                Id:
                <b>{comp.uid}</b>
              </li>
              <li>
                Acesso:
                <b>{access ? 'Liberado' : 'Negado'}</b>
              </li>
              <styles.OrderSelect>
                <button
                  type="button"
                  className={access ? 'btn_select' : ''}
                  onClick={() => setAccess(true)}
                >
                  Liberado
                </button>
                <button
                  type="button"
                  className={access ? '' : 'btn_select'}
                  onClick={() => setAccess(false)}
                >
                  Negado
                </button>
              </styles.OrderSelect>
            </>
          ))}
        </styles.OrderList>

        <button type="button" className="button_submit" onClick={handleUpdate}>Editar</button>

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
