import styled from 'styled-components';

export const ComapanyTables = styled.table`
  width: 100%;
  margin-top: 30px;
  border: 1px solid ${(props) => props.theme.textDark};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.backgroundHover};

  tbody tr:nth-child(odd){
    background-color: ${(props) => props.theme.background};
  }

  tr, td, th{
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 16px;
  }

  th{
    padding: 10px;
    font-weight: 600;
    text-align: center;
  }

  td{
    padding: 10px 7px;
    text-align: center;
    font-weight: 400;

    span{
      padding: 5px 8px;
      border-radius: 0.5rem;
      color: ${(props) => props.theme.textLigth};
    }

    img{
      width: 100%;
      max-width: 32px;
    }

    .table_access_false{
      background-color: #F5190A;
    }

    .table_access_true{
      background-color: #01F510;
    }
    
    .table_access_progress{
      background-color: #D7A800;
    }

    .table_edit{
      background-color: #D7A800;
      color: ${(props) => props.theme.textLigth};
      width: 32px;
      height: 32px;
      padding: 7px;
      cursor: pointer;
      border-radius: 0.2rem;
    }

    .table_info{
      background-color: #1A7DF4;
      color: ${(props) => props.theme.textLigth};
      width: 32px;
      height: 32px;
      padding: 2px;
      cursor: pointer;
      border-radius: 0.2rem;
    }
  }

    .table_access_false{
    color: ${(props) => props.theme.textLigth};
    background-color: #F5190A;
  }

  .table_access_true{
    color: ${(props) => props.theme.textLigth};
    background-color: #01F510;
  }
  
  .table_access_progress{
    color: ${(props) => props.theme.textLigth};
    background-color: #D7A800;
  }
`;

export const CompanySearch = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const CompanySearchGroup = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: 40px;

  h3{
    font-size: 18px;
    font-weight: 400;
  }

  input{
    width: 100%;
    max-width: 250px;
    font-size: 16px;
    padding: 7px 25px 7px 10px;
    margin-top: 10px;
    border: 1px solid ${(props) => props.theme.textDark};
    border-radius: 0.2rem;
  }

  svg{
    font-size: 20px;
    position: absolute;
    bottom: 7px;
    left: 220px;
  }

  div{
    display: flex;
    align-items: center;
    justify-content: center;

    input{
      width: 20px;
      height: 20px;
      border-radius: 100%;
      border: 1px solid ${(props) => props.theme.textDark};
      cursor: pointer;
      margin-right: 10px;
      background-color: red;
    }

    span{
      margin-top: 6px;
    }
  }
`;

export const CompanyClear = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  margin-top: 20px;

  svg{
    font-size: 45px;
  }

  h3{
    font-size: 20px;
    margin-top: 10px;
  }

  span{
    font-size: 16px;
    margin-top: 5px;
  }

  button{
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    padding: 8px;
    cursor: pointer;
    font-size: 16px;
    color: ${(props) => props.theme.textLigth};
    border: 1px solid ${(props) => props.theme.textDark};
    border-radius: 0.5rem;
    background-color: ${(props) => props.theme.textDark};

    &:hover{
      transition: 0.7s;
      background-color: transparent;
      color: ${(props) => props.theme.textDark};
    }
  }
`;
