import React, { useEffect, useState } from 'react';
import { PiSuitcase } from 'react-icons/pi';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { IoInformation } from 'react-icons/io5';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import usePeople from '../../store/People';
import * as styles from './style';
import { ComapanyTables } from '../Company/style';

export default function CompanyId() {
  const [companyId, setCompanyId] = useState([]);
  const [applicationId, setApplicationId] = useState([]);
  const { id } = useParams();
  const { company, application } = usePeople();

  useEffect(() => {
    const compId = company.filter((comp) => comp.uid === id);
    const applId = application.filter((appl) => appl.userUid === id);

    console.log(compId);

    setCompanyId(compId);
    setApplicationId(applId);
  }, [company]);

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      default:
        return 'table_access_true';
    }
  };

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Pedidos da empresa" url="company" icon={<PiSuitcase />} />

          {companyId && (
            <styles.CompanyInfos>

              <Link to="/company">
                <FaArrowLeft />
              </Link>

              <styles.CompanyInfo>
                <h3>
                  Nome da empresa:
                  <b>{companyId.name}</b>
                </h3>

                <h3>
                  Documento da empresa:
                  <b>{companyId.docs}</b>
                </h3>
              </styles.CompanyInfo>

              <h4>Pedidos recentes</h4>

              {applicationId && (
              <ComapanyTables>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Data</th>
                    <th>Status</th>
                    <th>Categorias</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>

                  {applicationId.map((appl) => (
                    <tr>
                      <td>{appl.nameOrder}</td>
                      <td>{appl.dateFormat}</td>
                      <td>
                        <span className={changeCheck(appl.status)}>
                          {appl.status === 'pending' ? 'Pendente' : ''}
                          {appl.status === 'progress' ? 'Em andamento' : ''}
                          {appl.status === 'finish' ? 'Finalizado' : ''}
                        </span>
                      </td>
                      <td>{appl.orderSelect.length}</td>
                      <td>
                        <Link to={`/application/${appl.uid}`} className="table_info">
                          {' '}
                          <IoInformation />
                          {' '}
                        </Link>
                      </td>

                    </tr>
                  ))}
                </tbody>
              </ComapanyTables>
              )}

            </styles.CompanyInfos>
          )}

        </DashboardSt>
      </Center>
    </article>
  );
}
