import React from 'react';
import { IoClose } from 'react-icons/io5';
import { deleteDoc, doc } from 'firebase/firestore';
import * as styles from './style';
import { db } from '../../service/firebaseConnection';
import useOrder from '../../store/Order';

export default function FormDelete({ func, uid }) {
  const { order, setOrders } = useOrder();

  console.log(uid);

  const deleteOrder = async () => {
    try {
      await deleteDoc(doc(db, 'orders', uid));
      setOrders(order.filter((od) => od.id !== uid));
      func();
    } catch (e) {
      console.error('Error removing document: ', e);
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Tem certeza que deseja excluir este pedido?</h2>

        <styles.OrderFormButton>
          <button className="cancelar" type="button" onClick={func}>Cancelar</button>
          <button className="excluir" type="button" onClick={deleteOrder}>Continuar, desejo excluir</button>
        </styles.OrderFormButton>

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
