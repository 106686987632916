import React from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import * as styles from './style';
import banner from '../../assets/section.png';

export default function Title({
  text, url = '', icon,
}) {
  return (
    <styles.TitleSt $isBanner={banner}>

      <h1>{text}</h1>

      <styles.TitleUrl>
        <Link to="/">
          <AiOutlineHome />
          <span>Home</span>
        </Link>
        {' '}
        <p>/</p>
        {' '}
        <Link to={`/${url}`}>
          {icon}
          <span>{text}</span>
        </Link>
      </styles.TitleUrl>

    </styles.TitleSt>
  );
}
