import React, { useEffect, useState } from 'react';
import { PiSuitcase } from 'react-icons/pi';
import { Link, useParams } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa6';
import { IoEyeSharp } from 'react-icons/io5';
import { IoMdDownload } from 'react-icons/io';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { doc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import usePeople from '../../store/People';
import { db, storage } from '../../service/firebaseConnection';
import Header from '../../components/Header';
import Center from '../../components/Center';
import Title from '../../components/Title';
import { DashboardSt } from '../Dashboard/style';
import { CompanyInfos } from '../CompanyId/style';
import * as styles from './style'; // Verifique se o caminho está correto

export default function ApplicationId() {
  const { id } = useParams();
  const [applicationId, setApplicationId] = useState([]);
  const { application, fetchApplication } = usePeople();
  const [check, setCheck] = useState(false);
  const [load, setLoad] = useState(false);
  const [haveFile, setHaveFile] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const [orderId, setOrderId] = useState('');

  useEffect(() => {
    fetchApplication();
  }, []);

  useEffect(() => {
    const appl = application.filter((ap) => ap.uid === id);
    setApplicationId(appl);
    if (appl.length > 0) {
      setCheck(appl[0].status !== 'pending');
    }
  }, [application, id]);

  const generatePdf = () => {
    const docPDF = new jsPDF();

    docPDF.setFont('helvetica', 'bold');
    docPDF.setFontSize(18);
    docPDF.text('Detalhes do pedido', 20, 10);

    docPDF.setFont('helvetica', 'normal');
    docPDF.setFontSize(12);
    docPDF.text('Este relatório contém informações detalhadas sobre o pedido.', 20, 20);
    docPDF.text('Por favor, veja abaixo os detalhes do pedido e de cada requisição.', 20, 30);

    if (applicationId.length > 0) {
      const appl = applicationId[0];
      docPDF.setFont('helvetica', 'bold');
      docPDF.setFontSize(12);
      docPDF.text(`Dados do requisitor: ${appl.userDoc}`, 20, 40);

      docPDF.setFont('helvetica', 'bold');
      docPDF.setFontSize(12);
      docPDF.text('Dados para a requisição', 20, 50);

      docPDF.setFont('helvetica', 'normal');
      docPDF.setFontSize(12);
      docPDF.text(`Nome: ${appl.name}`, 20, 60);
      docPDF.text(`Documento: ${appl.cpf}`, 20, 70);
      docPDF.text(`Estado: ${appl.state}`, 20, 80);

      docPDF.setFont('helvetica', 'bold');
      docPDF.setFontSize(12);
      docPDF.text(`Número de pedidos: ${appl.orderSelect.length}`, 20, 90);

      docPDF.setFont('helvetica', 'normal');
      docPDF.setFontSize(12);
      let num = 100;

      appl.orderSelect.forEach((element) => {
        docPDF.text(`- ${element.name}`, 20, num);
        num += 10;
      });
    }

    docPDF.save('detalhes_do_pedido.pdf');
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const saveFileURL = async (url) => {
    setLoad(true);

    if (applicationId.length > 0) {
      const appl = applicationId[0];
      const updatedOrderSelect = appl.orderSelect.map((order) => (order.id === orderId ? { ...order, file: url } : order));

      try {
        const orderDocRef = doc(db, 'application', id);

        await updateDoc(orderDocRef, { orderSelect: updatedOrderSelect });

        const allHaveFile = updatedOrderSelect.every((order) => order.file !== undefined && order.file !== '');

        if (allHaveFile) {
          await updateDoc(orderDocRef, { status: 'finish' });
          setHaveFile(true);
        }
        setApplicationId([{ ...appl, orderSelect: updatedOrderSelect }]);
        console.log('Documento atualizado com sucesso!');
      } catch (error) {
        console.error('Erro ao atualizar o documento:', error);
      } finally {
        fetchApplication();
        setLoad(false);
      }
    }
  };

  const handleUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `application/${orderId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progres = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progres);
      },
      (error) => {
        console.error('Erro ao fazer o upload do arquivo:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          saveFileURL(downloadURL);
        });
      },
    );
  };

  const handleChecked = async () => {
    const orderDocRef = doc(db, 'application', id);
    await updateDoc(orderDocRef, { status: !check ? 'progress' : 'pending' });
    fetchApplication();
  };

  return (
    <article className="article_flex">
      <Header />
      <Center>
        <DashboardSt>
          <Title text="Informações do pedido" url="application" icon={<PiSuitcase />} />
          <CompanyInfos>
            <Link to="/application">
              <FaArrowLeft />
            </Link>
            {applicationId.length > 0 && (
              <styles.ApplId>
                <styles.ApplDowload>
                  <span>Detalhes do pedido: </span>
                  <button type="button" onClick={generatePdf}>
                    <IoMdDownload />
                    Fazer download do pdf
                  </button>
                </styles.ApplDowload>
                {!haveFile && (
                  <styles.ApplProgress>
                    <input type="checkbox" checked={check} onChange={(e) => setCheck(e.target.checked)} onClick={handleChecked} />
                    <span>Pedido em processo</span>
                  </styles.ApplProgress>
                )}
                <styles.ApplTable>
                  <thead>
                    <tr>
                      <th>Solicitação</th>
                      <th>Enviar documento</th>
                      <th>Status</th>
                      <th>#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {applicationId[0].orderSelect.map((os) => (
                      <styles.ApplOrder key={os.id}>
                        <td>
                          {os.name}
                          :
                        </td>
                        <td>
                          <input type="file" multiple onChange={handleFileChange} onClick={() => setOrderId(os.id)} />
                        </td>
                        <td className={os.file ? 'table_access_true' : 'table_access_false'}>
                          {os.file ? 'Finalizado' : 'Pendente'}
                        </td>
                        <td>
                          <a href={os.file} target="blank" rel="noreferrer">
                            <button type="button" className="btn_view">
                              <IoEyeSharp />
                              Ver documento
                            </button>
                          </a>
                        </td>
                      </styles.ApplOrder>
                    ))}
                  </tbody>
                </styles.ApplTable>
                {progress > 0 && (
                  <div className="progress">
                    <input type="range" value={progress} readOnly />
                    <p>
                      {progress}
                      %
                    </p>
                  </div>
                )}
                <button type="button" className="button_submit" onClick={handleUpload}>
                  {load ? 'Enviando...' : 'Enviar'}
                </button>
              </styles.ApplId>
            )}
          </CompanyInfos>
        </DashboardSt>
      </Center>
    </article>
  );
}
