import React, { useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import * as styles from './style';
import { db } from '../../service/firebaseConnection';
import useUser from '../../store/User';

export default function Sign() {
  const [key, setKey] = useState('');
  const [password, setPassword] = useState('');

  const {
    storageUser,
  } = useUser();

  const verifAccess = async (e) => {
    e.preventDefault();

    if (key && password) {
      const userDocRef = doc(db, 'admin', key);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.data()) {
        if (userDoc.data().password === password) {
          storageUser(userDoc.data());
        }
      }
    }
  };

  return (
    <styles.SignSt>
      <h3>Entre na sua conta de ADM</h3>

      <styles.SignForm>
        <span>Digite sua chave de acesso:</span>
        <input type="text" placeholder="Digite a sua chave de acesso" value={key} onChange={(e) => setKey(e.target.value)} />

        <span>Digite sua senha especial:</span>
        <input type="password" placeholder="Digite a sua chave senha" value={password} onChange={(e) => setPassword(e.target.value)} />
        <button type="submit" onClick={verifAccess}>Entrar</button>
      </styles.SignForm>
    </styles.SignSt>
  );
}
