import React, { useEffect, useState } from 'react';
import { PiSuitcase } from 'react-icons/pi';
import { FaPencil } from 'react-icons/fa6';
import { IoInformation } from 'react-icons/io5';
import { IMaskInput } from 'react-imask';
import { GrClear } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import usePeople from '../../store/People';
import * as styles from './style';
import { MyRequestFilter, MyRequestFilterGroup, MyRequestFilterInput } from '../Order/style';
import CnpjEdit from '../../components/CnpjEdit';
import CnpjInfo from '../../components/CnpjInfo';

export default function Company() {
  const { company, fetchCompany } = usePeople();
  const [search, setSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [companySearch, setCompanySearch] = useState([]);
  const [checkNeg, setCheckNeg] = useState(false);
  const [checkLib, setCheckLib] = useState(false);
  const [showCnpj, setShowCnpj] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [show, setShow] = useState(false);
  const [idCnpj, setIdCnpj] = useState('');

  useEffect(() => {
    fetchCompany();
  }, []);

  useEffect(() => {
    setCompanySearch(company);
  }, [company]);

  useEffect(() => {
    let filteredCompanies = company;

    if (search.trim() !== '') {
      const lowerCasedCnpj = search.toLowerCase();
      filteredCompanies = filteredCompanies.filter((comp) => comp.cnpj.toLowerCase().includes(lowerCasedCnpj));
    }

    if (emailSearch.trim() !== '') {
      const lowerCasedEmail = emailSearch.toLowerCase();
      filteredCompanies = filteredCompanies.filter((comp) => comp.email.toLowerCase().includes(lowerCasedEmail));
    }

    if (nameSearch.trim() !== '') {
      const lowerCasedName = nameSearch.toLowerCase();
      filteredCompanies = filteredCompanies.filter((comp) => comp.name.toLowerCase().includes(lowerCasedName));
    }

    if (checkLib) {
      filteredCompanies = filteredCompanies.filter((comp) => comp.access === true);
    }

    if (checkNeg) {
      filteredCompanies = filteredCompanies.filter((comp) => comp.access === false);
    }

    setCompanySearch(filteredCompanies);
  }, [search, emailSearch, nameSearch, checkLib, checkNeg, company]);

  const clearFilters = () => {
    setSearch('');
    setEmailSearch('');
    setNameSearch('');
    setCheckNeg(false);
    setCheckLib(false);
  };

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Controle de empresas" url="company" icon={<PiSuitcase />} />

          <styles.CompanySearch>

            {company && (
              company.length > 0 && (
                <MyRequestFilter>
                  <button type="button" onClick={() => setShow(!show)}>
                    Filtro
                    {show ? <IoIosArrowDown /> : <IoIosArrowUp />}
                  </button>

                  {show && (
                    <MyRequestFilterGroup>
                      <MyRequestFilterInput>
                        <span>CNPJ</span>
                        <IMaskInput mask="00.000.000/0000-00" type="text" name="cnpj" placeholder="Digite o CNPJ" value={search} onChange={(e) => setSearch(e.target.value)} />
                      </MyRequestFilterInput>

                      <MyRequestFilterInput>
                        <span>Nome da Empresa</span>
                        <input type="text" name="name" placeholder="Digite o nome da empresa" value={nameSearch} onChange={(e) => setNameSearch(e.target.value)} />
                      </MyRequestFilterInput>

                      <MyRequestFilterInput>
                        <span>Email</span>
                        <input type="text" name="email" placeholder="Digite o email" value={emailSearch} onChange={(e) => setEmailSearch(e.target.value)} />
                      </MyRequestFilterInput>

                      <MyRequestFilterInput>
                        <span>Filtrar por acesso</span>

                        <div>
                          <input type="radio" name="check" checked={checkNeg} onChange={(e) => { setCheckNeg(e.target.checked); setCheckLib(false); }} />
                          <span>Negado</span>

                          <input type="radio" name="check" checked={checkLib} onChange={(e) => { setCheckLib(e.target.checked); setCheckNeg(false); }} />
                          <span>Liberado</span>
                        </div>
                      </MyRequestFilterInput>

                    </MyRequestFilterGroup>
                  )}
                </MyRequestFilter>
              )
            )}
          </styles.CompanySearch>

          {companySearch && companySearch.length <= 0 && (
            <styles.CompanyClear>
              <GrClear />
              <h3>Nenhuma empresa encontrada</h3>
              <span>Verifique os dados do filtro, clique no botão para limpar os filtros</span>
              <button type="button" onClick={clearFilters}>Limpar filtros</button>
            </styles.CompanyClear>
          )}

          {companySearch && companySearch.length > 0 && (

            <styles.ComapanyTables>
              <thead>
                <tr>
                  <th>Cnpj</th>
                  <th>Nome da empresa</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>Acesso</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>

                {companySearch && (companySearch.map((cop) => (
                  <tr key={cop.uid}>
                    <td>{cop.cnpj}</td>
                    <td>{cop.name}</td>
                    <td>{cop.email}</td>
                    <td>{cop.tel}</td>
                    <td>
                      <span className={cop.access ? 'table_access_true' : 'table_access_false'}>
                        {cop.access ? 'Liberado' : 'Negado'}
                      </span>
                    </td>
                    <td>
                      {' '}
                      <FaPencil className="table_edit" onClick={() => { setShowCnpj(true); setIdCnpj(cop.uid); }} />
                      {' '}
                      <Link to={`/user/${cop.uid}`}>
                        <IoInformation className="table_info" />
                      </Link>
                    </td>
                  </tr>
                )))}
              </tbody>
            </styles.ComapanyTables>
          )}

          {showCnpj && (
            <CnpjEdit func={() => setShowCnpj(false)} uid={idCnpj} />
          )}

          {showInfo && (
            <CnpjInfo func={() => setShowInfo(false)} uid={idCnpj} setShow={setShow} />
          )}

        </DashboardSt>
      </Center>
    </article>
  );
}
