import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import {
  addDoc, collection,
} from 'firebase/firestore';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import * as styles from './style';
import { db } from '../../service/firebaseConnection';
import useOrder from '../../store/Order';
import Inputs from '../Inputs';
import Value from '../Value';
import { OrderFormShow } from '../FormOrder/style';

export default function FormSearch({ func }) {
  const initialFields = {
    nomeMae: false,
    nomePai: false,
    dataNascimento: false,
    naturalidade: false,
    genero: false,
    dataAbertura: false,
    numeroNire: false,
    imovel: false,
    numeroMatricula: false,
    numeroTranscricao: false,
    numeroRegistro: false,
    enderecoCompleto: false,
    matriculaMae: false,
    notas: false,
    livro: false,
    folha: false,
    dataLavratura: false,
    civil: false,
    livroCivil: false,
    folhaCivil: false,
    dataRegistroCivil: false,
    numeroItr: false,
    numeroRip: false,
    numeroIe: false,
    municipais: false,
    numeroIptu: false,
  };

  const initialAddressFields = {
    estado: '',
    cidade: '',
    valor: '',
  };

  const [addresses, setAddresses] = useState([initialAddressFields]);

  const [name, setName] = useState('');
  const [prince, setPrince] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [inputs, setInputs] = useState([initialFields]);
  const [checkbox, setCheckbox] = useState(false);
  const [show, setShow] = useState(false);

  const { fetchOrders } = useOrder();

  const submitSearch = async () => {
    if (name !== '' && selectedOption !== '') {
      try {
        const data = {
          type: 'search',
          name,
          selectedOption,
          prince,
          inputs,
          addresses,
        };

        await addDoc(collection(db, 'orders'), data);

        fetchOrders();

        setName('');
        setSelectedOption('');
        setPrince(0);
        setInputs(initialFields);
        setAddresses(initialAddressFields);
        setCheckbox(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Cadastre uma pesquisa</h2>

        <styles.OrderFormGroup>
          <styles.OrderFormInput>
            <span>Nome do pedido</span>
            <input type="text" placeholder="Digite um nome" value={name} onChange={(e) => setName(e.target.value)} />
          </styles.OrderFormInput>

          <styles.OrderFormSelect>
            <span>Selecione a coleção</span>
            <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
              <option selected>Escolha a coleção</option>
              <option value="pessoas-empresa">Pessoas/Empresa</option>
              <option value="debitos-pendencias">Débitos/Pendências</option>
              <option value="bens-moveis">Bens Móveis</option>
              <option value="bens-imoveis">Bens Imóveis</option>
            </select>
          </styles.OrderFormSelect>
        </styles.OrderFormGroup>

        <styles.OrderFormGroup>
          <styles.OrderFormInput>
            <span>Valor do pedido</span>
            <input type="number" placeholder="Adicione um valor" value={prince} onChange={(e) => setPrince(e.target.value)} />
          </styles.OrderFormInput>

          <styles.OrderFormCheckbox>
            <span>Informações adicionais</span>
            <input type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
          </styles.OrderFormCheckbox>
        </styles.OrderFormGroup>

        {checkbox && (
          <Inputs setInputs={setInputs} inputs={inputs} />
        )}

        <OrderFormShow>
          <button type="button" onClick={() => setShow(!show)}>
            {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
            {' '}
            Adicionar valores extras
          </button>
        </OrderFormShow>

        {show && (
          <Value setAddresses={setAddresses} addresses={addresses} />
        )}

        <button type="button" className="button_submit" onClick={submitSearch}>Adicionar</button>

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
