import styled from 'styled-components';

export const DashboardSt = styled.section`
  padding: 30px 0 20px 0;
`;

export const DashboardGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
`;

export const DashboardInfos = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 350px;
  padding: 12px 20px;
  border-radius: 0.5rem;

  h3{
    font-size: 16px;
    font-weight: 400;

    b{
      margin-left: 5px;
    }
  }
`;
