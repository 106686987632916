import React from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { theme } from './theme/style';
import { GlobalStyles } from './GlobalStyles';
import RoutesComp from './routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />

      <BrowserRouter>
        <RoutesComp />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
