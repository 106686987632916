import styled from 'styled-components';

export const ValueSt = styled.div`
  padding: 5px;

  .addButton{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background-color: #D7A800;
    padding: 10px 25px;
    color: ${(props) => props.theme.textLigth};
    border: 0;
    margin-top: 30px;
    border-radius: 0.5rem;
    cursor: pointer;
  }
`;

export const ValueGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px 0;

  span{
    display: flex;
    flex-direction: column;
    font-size: 16px;

    select, input{
      margin-top: 5px;
      font-size: 14px;
      padding: 3px;
      color: ${(props) => props.theme.textDark};
      cursor: pointer;
    }
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    background-color: #F5190A;
    padding: 10px;
    color: ${(props) => props.theme.textLigth};
    border: 0;
    border-radius: 0.5rem;
    cursor: pointer;
  }
`;
