import React, { useEffect, useState } from 'react';
import { IoClose, IoInformation } from 'react-icons/io5';
import * as styles from './style';
import usePeople from '../../store/People';

export default function CnpjInfo({
  func, uid, setShow, setIdAppl,
}) {
  const [thisCompany, setThisCompany] = useState([]);
  const [thisApplication, setThisApplication] = useState([]);
  const { company, application } = usePeople();

  useEffect(() => {
    const c = company.filter((comp) => comp.uid === uid);
    const a = application.filter((appl) => appl.userUid === uid);

    setThisCompany(c);
    setThisApplication(a);
    console.log(a);
  }, [company, application]);

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      default:
        return 'table_access_true';
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Informações do Cnpj</h2>

        <styles.OrderInfo>
          {thisCompany.map((comp) => (
            <>
              <span>
                Nome da empresa:
                <b>
                  {comp.name}
                </b>
              </span>
              <span>
                CNPJ da empresa:
                <b>
                  {comp.cnpj}
                </b>
              </span>
            </>
          ))}
        </styles.OrderInfo>

        <styles.OrderApplication>
          <h3>Pedidos recentes</h3>

          {thisApplication.map((appl) => (
            <styles.ApplicationList>

              <span>{appl.nameOrder}</span>
              <span>{appl.dateFormat}</span>
              <span className={changeCheck(appl.status)}>
                {appl.status === 'pending' ? 'Pendente' : ''}
                {appl.status === 'progress' ? 'Em andamento' : ''}
                {appl.status === 'finish' ? 'Finalizado' : ''}
              </span>
              <span>
                Categorias:
                <b>{appl.orderSelect.length}</b>
              </span>
              <button type="button">
                <IoInformation onClick={() => { setShow(true); setIdAppl(appl.uid); }} />
              </button>

            </styles.ApplicationList>
          ))}
        </styles.OrderApplication>

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
