import React, { useEffect, useState } from 'react';
import { FaRegUser } from 'react-icons/fa6';
import { IMaskInput } from 'react-imask';
import { IoInformation } from 'react-icons/io5';
import { GrClear } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import usePeople from '../../store/People';
import * as styles from '../Company/style';
import CpfInfo from '../../components/CpfInfo';
import { MyRequestFilter, MyRequestFilterGroup, MyRequestFilterInput } from '../Order/style';

export default function Users() {
  const [userSearch, setUserSearch] = useState([]);
  const [cpfSearch, setCpfSearch] = useState('');
  const [nameSearch, setNameSearch] = useState('');
  const [emailSearch, setEmailSearch] = useState('');
  const [showCpf, setShowCpf] = useState(false);
  const [show, setShow] = useState(false);

  const { user, fetchUser } = usePeople();

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    setUserSearch(user);
    console.log(user);
  }, [user]);

  useEffect(() => {
    let filteredUsers = user;

    if (cpfSearch.trim() !== '') {
      const lowerCasedCpf = cpfSearch.toLowerCase();
      filteredUsers = filteredUsers.filter((u) => u.cpf.toLowerCase().includes(lowerCasedCpf));
    }

    if (nameSearch.trim() !== '') {
      const lowerCasedName = nameSearch.toLowerCase();
      filteredUsers = filteredUsers.filter((u) => u.name.toLowerCase().includes(lowerCasedName));
    }

    if (emailSearch.trim() !== '') {
      const lowerCasedEmail = emailSearch.toLowerCase();
      filteredUsers = filteredUsers.filter((u) => u.email.toLowerCase().includes(lowerCasedEmail));
    }

    setUserSearch(filteredUsers);
  }, [cpfSearch, nameSearch, emailSearch, user]);

  const clearFilters = () => {
    setCpfSearch('');
    setNameSearch('');
    setEmailSearch('');
  };

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Controle de usuários" url="users" icon={<FaRegUser />} />

          <styles.CompanySearch>

            {user && (
              user.length > 0 && (
                <MyRequestFilter>
                  <button type="button" onClick={() => setShow(!show)}>
                    Filtro
                    {show ? <IoIosArrowDown /> : <IoIosArrowUp />}
                  </button>

                  {show && (
                    <MyRequestFilterGroup>
                      <MyRequestFilterInput>
                        <span>Pesquisar por CPF</span>
                        <IMaskInput mask="000.000.000-00" type="text" name="cpf" placeholder="Digite o CPF" value={cpfSearch} onChange={(e) => setCpfSearch(e.target.value)} />
                      </MyRequestFilterInput>

                      <MyRequestFilterInput>
                        <span>Pesquisar por Nome</span>
                        <input type="text" name="name" placeholder="Digite o nome" value={nameSearch} onChange={(e) => setNameSearch(e.target.value)} />

                      </MyRequestFilterInput>

                      <MyRequestFilterInput>
                        <span>Pesquisar por Email</span>
                        <input type="text" name="email" placeholder="Digite o email" value={emailSearch} onChange={(e) => setEmailSearch(e.target.value)} />

                      </MyRequestFilterInput>

                    </MyRequestFilterGroup>
                  )}
                </MyRequestFilter>
              )
            )}
          </styles.CompanySearch>

          {userSearch && userSearch.length <= 0 && (
            <styles.CompanyClear>
              <GrClear />
              <h3>Nenhuma usuário encontrado</h3>
              <span>Verifique os dados do filtro, clique no botão para limpar os filtros</span>
              <button type="button" onClick={clearFilters}>Limpar filtros</button>
            </styles.CompanyClear>
          )}

          {userSearch && userSearch.length > 0 && (
            <styles.ComapanyTables>
              <thead>
                <tr>
                  <th>Cpf</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  <th>Usuário</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>
                {userSearch && (userSearch.map((cop) => (
                  <tr key={cop.uid}>
                    <td>{cop.cpf}</td>
                    <td>{cop.name}</td>
                    <td>{cop.email}</td>
                    <td>{cop.tel}</td>
                    <td><img src={cop.img} alt="logo" title={cop.name} /></td>
                    <td>
                      <Link to={`/user/${cop.uid}`}>
                        <IoInformation className="table_info" />
                      </Link>
                    </td>
                  </tr>
                )))}
              </tbody>
            </styles.ComapanyTables>
          )}

          {showCpf && (
            <CpfInfo func={() => setShowCpf(false)} setShow={setShow} />
          )}

        </DashboardSt>
      </Center>
    </article>
  );
}
