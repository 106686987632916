import React, { useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { doc, updateDoc } from 'firebase/firestore';
import * as styles from './style';
import { db } from '../../service/firebaseConnection';
import useOrder from '../../store/Order';

export default function FormEdit({ func, uid }) {
  const [name, setName] = useState('');
  const [prince, setPrince] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [thisOrder, setThisOrder] = useState({});

  const { order, setOrders } = useOrder();

  useEffect(() => {
    function fetchOrder() {
      const dataId = order.filter((od) => od.id === uid);

      setThisOrder(dataId[0]);
      setName(dataId[0].name);
      setPrince(dataId[0].prince);
      setSelectedOption(dataId[0].selectedOption);
      setCheckbox(dataId[0].checkbox);
    }

    fetchOrder();
  }, []);

  const handleSubmit = async () => {
    if (name !== '' && selectedOption !== '') {
      try {
        const orderDocRef = doc(db, 'orders', uid);

        if (thisOrder.type === 'order') {
          const data = {
            type: 'order',
            name,
            selectedOption,
            prince,
            checkbox,
          };

          await updateDoc(orderDocRef, data);

          const editData = order.map((od) => (od.id === uid ? { ...od, ...data } : od));

          setOrders(editData);
        } else {
          const data = {
            type: 'search',
            name,
            prince,
            selectedOption,
          };

          await updateDoc(orderDocRef, data);

          const editData = order.map((od) => (od.id === uid ? { ...od, ...data } : od));

          setOrders(editData);
        }
      } catch (e) {
        console.error('Error updating document: ', e);
      }
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Editar um pedido</h2>

        <styles.OrderFormGroup>
          <styles.OrderFormInput>
            <span>Nome do pedido</span>
            <input type="text" placeholder="Digite um nome" value={name} onChange={(e) => setName(e.target.value)} />
          </styles.OrderFormInput>

          <styles.OrderFormSelect>
            <span>Selecione a coleção</span>

            {thisOrder.type === 'order' ? (
              <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                <option value="registro Civil">Registro Civil</option>
                <option value="registro de Imóveis">Registro de Imóveis</option>
                <option value="notas">Notas</option>
                <option value="protesto">Protesto</option>
                <option value="federais">Federais</option>
                <option value="estaduais">Estaduais</option>
                <option value="municipais">Municipais</option>
              </select>
            ) : (
              <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                <option value="pessoas-empresa">Pessoas/Empresa</option>
                <option value="debitos-pendencias">Débitos/Pendências</option>
                <option value="bens-moveis">Bens Móveis</option>
                <option value="bens-imoveis">Bens Imóveis</option>
              </select>
            )}
          </styles.OrderFormSelect>
        </styles.OrderFormGroup>

        <styles.OrderFormGroup>

          <styles.OrderFormInput>
            <span>Valor do pedido</span>
            <input type="number" placeholder="Adicione um valor" value={prince} onChange={(e) => setPrince(e.target.value)} />
          </styles.OrderFormInput>

          {thisOrder.type === 'order' ? (
            <styles.OrderFormCheckbox>
              <span>Informações adicionais</span>
              <input type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
            </styles.OrderFormCheckbox>
          ) : (
            <i />
          )}

        </styles.OrderFormGroup>

        <button type="button" className="button_submit" onClick={handleSubmit}>Editar</button>

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
