import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { create } from 'zustand';
import { db } from '../service/firebaseConnection';

const usePeople = create((set) => ({
  peoples: [],

  setCompany: (company) => {
    set({ company });
  },

  fetchCompany: async () => {
    try {
      const q = query(collection(db, 'users'), where('cnpj', '!=', ''));
      const querySnapshot = await getDocs(q);
      const companyData = querySnapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));

      set({ company: companyData });
    } catch (e) {
      console.error(e);
    }
  },

  setUser: (user) => {
    set({ user });
  },

  fetchUser: async () => {
    try {
      const q = query(collection(db, 'users'), where('cpf', '!=', ''));
      const querySnapshot = await getDocs(q);
      const userData = querySnapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));

      set({ user: userData });
    } catch (e) {
      console.error(e);
    }
  },

  setApplication: (appl) => {
    set({ application: appl });
  },

  fetchApplication: async () => {
    try {
      const q = collection(db, 'application');
      const querySnapshot = await getDocs(q);
      const applicationData = querySnapshot.docs.map((doc) => ({ uid: doc.id, ...doc.data() }));

      set({ application: applicationData });
    } catch (e) {
      console.error(e);
    }
  },

}));

export default usePeople;
