import React from 'react';
import { FaPencil, FaPowerOff, FaRegUser } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { PiSuitcase } from 'react-icons/pi';
import { RiDraftLine, RiSettings3Line } from 'react-icons/ri';
import * as styles from './style';
import img from '../../assets/jk-logo.png';
import useUser from '../../store/User';

export default function Header() {
  const links = [
    { text: 'Controle de serviços', href: '/order', icon: <FaPencil /> },
    { text: 'Empresas', href: '/company', icon: <PiSuitcase /> },
    { text: 'Usuários', href: '/users', icon: <FaRegUser /> },
    { text: 'Pedidos', href: '/application', icon: <RiDraftLine /> },
    { text: 'Configurações', href: '/settings', icon: <RiSettings3Line /> },
  ];

  const { logoutUser } = useUser();

  return (
    <styles.HeaderSt>
      <Link to="/">
        <img src={img} alt="logo" />
      </Link>

      <h3>ADMIN</h3>

      <styles.HeaderUl>
        {links.map((link, index) => (
          <li id={index}>
            <Link to={link.href}>
              {link.icon}
              {' '}
              {link.text}
            </Link>
          </li>
        ))}
      </styles.HeaderUl>

      <button onClick={logoutUser} type="button">
        <FaPowerOff />
        {' '}
        <span>Sair</span>
      </button>
    </styles.HeaderSt>
  );
}
