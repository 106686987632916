import React, { useEffect, useState } from 'react';
import { FaPencil, FaPlus } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { MdOutlineBorderColor } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import FormOrder from '../../components/FormOrder';
import * as styles from './style';
import FormSearch from '../../components/FormSearch';
import useOrder from '../../store/Order';
import FormDelete from '../../components/FormDelete';
import FormEdit from '../../components/FormEdit';

export default function Order() {
  const [formOrder, setFormOrder] = useState(false);
  const [formSearch, setFormSearch] = useState(false);
  const [formDelete, setFormDelete] = useState(false);
  const [formEdit, setFormEdit] = useState(false);
  const [show, setShow] = useState(false);
  const [filterName, setFilterName] = useState('');
  const [filterCol, setFilterCol] = useState('');

  // Handlers for input changes
  const handleFilterNameChange = (e) => setFilterName(e.target.value);
  const handleFilterColChange = (e) => setFilterCol(e.target.value);

  const [id, setId] = useState('');

  const { fetchOrders, order } = useOrder();

  useEffect(() => {
    fetchOrders();

    console.log(order);
  }, []);

  useEffect(() => {
    console.log(order);
  }, [order]);

  const filteredOrders = order ? order.filter((orde) => (
    (filterName ? orde.name.toLowerCase().includes(filterName.toLowerCase()) : true)
      && (filterCol ? orde.selectedOption.includes(filterCol) : true)
  )) : order;

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Controle de serviços" url="order" icon={<FaPencil />} />

          <styles.OrderButtonGroup>

            <Link to="/request">
              <styles.OrderButton>
                <FaPlus />
                {' '}
                Adicionar solicitação
              </styles.OrderButton>
            </Link>

            <Link to="/search">
              <styles.OrderButton>
                <FaPlus />
                {' '}
                Adicionar pesquisa
              </styles.OrderButton>
            </Link>

          </styles.OrderButtonGroup>

          {order && (
            order.length > 0 && (
            <styles.MyRequestFilter>
              <button type="button" onClick={() => setShow(!show)}>
                Filtro
                {show ? <IoIosArrowDown /> : <IoIosArrowUp />}
              </button>

              {show && (
              <styles.MyRequestFilterGroup>
                <styles.MyRequestFilterInput>
                  <span>Nome do pedido</span>
                  <input
                    type="text"
                    placeholder="Nome do pedido"
                    value={filterName}
                    onChange={handleFilterNameChange}
                  />
                </styles.MyRequestFilterInput>

                <styles.MyRequestFilterInput>
                  <span>Coleção</span>
                  <input
                    type="text"
                    placeholder="Nome da coleção"
                    value={filterCol}
                    onChange={handleFilterColChange}
                  />
                </styles.MyRequestFilterInput>
              </styles.MyRequestFilterGroup>
              )}
            </styles.MyRequestFilter>
            )
          )}

          {filteredOrders && (
            <>
              {filteredOrders.length > 0 && (
              <styles.OrderTables>
                <thead>
                  <tr>
                    <th>Nome do pedido</th>
                    <th>Tipo</th>
                    <th>Preço</th>
                    <th>Coleção</th>
                    <th>Info Adic.</th>
                    <th>#</th>
                  </tr>
                </thead>

                <tbody>
                  {filteredOrders.map((orde) => (
                    <tr key={orde.id}>
                      <td>{orde.name}</td>

                      <td>
                        {orde.type === 'order' ? 'Documento' : ''}
                        {orde.type === 'search' ? 'Consulta' : ''}
                      </td>

                      <td>
                        R$
                        {' '}
                        {parseFloat(orde.prince).toFixed(2).replace('.', ',')}
                      </td>

                      <td>
                        {orde.selectedOption.charAt(0).toUpperCase() + orde.selectedOption.substring(1)}
                      </td>

                      <td>{orde.checkbox === false ? 'Não' : 'Sim'}</td>

                      <td aria-hidden="true">
                        <Link to={`/order/${orde.id}`}>
                          <FaPencil className="table_edit" onClick={() => { setFormEdit(true); setId(orde.id); }} />
                        </Link>
                        {' '}
                        <IoClose className="table_close" onClick={() => { setFormDelete(true); setId(orde.id); }} />
                      </td>

                    </tr>
                  ))}
                </tbody>
              </styles.OrderTables>
              )}

              {filteredOrders.length <= 0 && (
                <styles.OrderClear>
                  <MdOutlineBorderColor />
                  <h3>Ainda não há pedidos criados</h3>
                  <span>Crie agora mesmo pedidos para que os usuários possam escolher</span>
                </styles.OrderClear>
              )}
            </>
          )}

          {formOrder && (
            <FormOrder func={() => setFormOrder(false)} />
          )}

          {formSearch && (
            <FormSearch func={() => setFormSearch(false)} />
          )}

          {formDelete && (
            <FormDelete func={() => setFormDelete(false)} uid={id} />
          )}

          {formEdit && (
            <FormEdit func={() => setFormEdit(false)} uid={id} />
          )}
        </DashboardSt>
      </Center>
    </article>
  );
}
