import styled from 'styled-components';

export const TitleSt = styled.section`

  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 15px 20px;
  border-radius: 0.5rem;
  background-image: url(${(props) => props.$isBanner});
  background-position: 100% 100%;
  background-size: cover;

  -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

  h1{
    font-size: 20px;
    font-weight: 500;
  }
`;

export const TitleUrl = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;

  p{
    margin: 0 12px;
  }

  a{

    &:nth-child(3){
      color: ${(props) => props.theme.layout};
    }

    padding: 2px 5px;
    display: flex;
    align-items: center;
    font-size: 14px;
    text-decoration: underline;

    svg{
      margin-right: 2px;
    }
  }
`;

export const TitleBalance = styled.div`
  width: 20%;
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.background};

  p{
    font-size: 16px;
    font-weight: 300;

    b{
      font-weight: 500;
      color: #00BD2C;
      margin-left: 5px;
    }
  }
`;
