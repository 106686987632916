import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Loading from '../components/Loading';
import useUser from '../store/User';

export default function RouteWrapper({
  loggedComponent, defaultComponent, IsPrivate, Access,
}) {
  const [signed, setSigned] = useState(false);

  const {
    loading, setLoading, storageUser, storage, access,
  } = useUser();

  // Pegar os dados do local Storage
  useEffect(() => {
    const checkUserAndStorage = () => {
      const local = localStorage.getItem('@JK-admin');
      if (local) {
        const userData = JSON.parse(local);
        if (userData) {
          setSigned(true);
          storageUser(userData);
        } else {
          setSigned(false);
        }
      } else {
        setSigned(false);
      }
      setLoading(false);
    };

    checkUserAndStorage();
  }, []);

  // Verificar se o storage mudou
  useEffect(() => {
    setLoading(true);
    if (storage) {
      setSigned(true);
    } else {
      setSigned(false);
    }
    setLoading(false);
  }, [storage]);

  if (loading) {
    return <Loading />;
  }

  if (!signed && IsPrivate) {
    return <Navigate to="/" />;
  }

  if (signed && !IsPrivate) {
    return <Navigate to="/dashboard" />;
  }

  if (!access && !Access && signed) {
    return <Navigate to="/dashboard" />;
  }

  return signed ? loggedComponent : defaultComponent;
}
