import styled from 'styled-components';

export const InputsSt = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 20px;

  > div{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  span{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
    font-size: 16px;
    margin: 3px 0;

    input{
      margin-right: 5px;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }
`;
