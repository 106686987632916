import React from 'react';
import { RiSettings3Line } from 'react-icons/ri';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import { DashboardSt } from '../Dashboard/style';

export default function Settings() {
  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Configurações do usuário" url="settings" icon={<RiSettings3Line />} />

        </DashboardSt>
      </Center>
    </article>
  );
}
