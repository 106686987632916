import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Sign from '../pages/Sign';
import RouteWrapper from './route';
import Dashboard from '../pages/Dashboard/index';
import Company from '../pages/Company';
import Order from '../pages/Order';
import Users from '../pages/Users';
import Application from '../pages/Application';
import Settings from '../pages/Settings';
import OrderEdit from '../pages/OrderEdit';
import Request from '../pages/Request';
import Search from '../pages/Search';
import CompanyId from '../pages/CompanyId';
import ApplicationId from '../pages/ApplicationId';

export default function RoutesComp() {
  return (
    <Routes>
      <Route path="/" element={<RouteWrapper loggedComponent={<Dashboard />} defaultComponent={<Sign />} />} />
      <Route path="/dashboard" element={<RouteWrapper loggedComponent={<Dashboard />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/order" element={<RouteWrapper loggedComponent={<Order />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/order/:id" element={<RouteWrapper loggedComponent={<OrderEdit />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/request" element={<RouteWrapper loggedComponent={<Request />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/search" element={<RouteWrapper loggedComponent={<Search />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/company" element={<RouteWrapper loggedComponent={<Company />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/user/:id" element={<RouteWrapper loggedComponent={<CompanyId />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/users" element={<RouteWrapper loggedComponent={<Users />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/application" element={<RouteWrapper loggedComponent={<Application />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/application/:id" element={<RouteWrapper loggedComponent={<ApplicationId />} defaultComponent={<Sign />} IsPrivate Access />} />

      <Route path="/settings" element={<RouteWrapper loggedComponent={<Settings />} defaultComponent={<Sign />} IsPrivate Access />} />
    </Routes>
  );
}
