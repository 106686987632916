import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaPencil } from 'react-icons/fa6';
import { Link, useParams } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import * as styles from './style';
import Inputs from '../../components/Inputs';
import Value from '../../components/Value';
import useOrder from '../../store/Order';

export default function OrderEdit() {
  const initialAddressFields = {
    estado: '',
    cidade: '',
    valor: '',
    cidades: [],
  };

  const [addresses, setAddresses] = useState([initialAddressFields]);
  const [inputs, setInputs] = useState([]);
  const [name, setName] = useState('');
  const [prince, setPrince] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [show, setShow] = useState(false);
  const [thisOrder, setThisOrder] = useState({});

  const { order, setOrders } = useOrder();
  const { id } = useParams();

  useEffect(() => {
    function fetchOrder() {
      const dataId = order.filter((od) => od.id === id);

      if (dataId.length > 0) {
        const orderData = dataId[0];
        setThisOrder(orderData);
        setInputs(orderData.inputs);
        setAddresses(orderData.addresses || [initialAddressFields]); // Ensure addresses is an array
        setName(orderData.name);
        setPrince(orderData.prince);
        setSelectedOption(orderData.selectedOption);
        setCheckbox(orderData.checkbox);
      }
    }

    fetchOrder();
  }, [id, order]);

  const handleSubmit = async () => {
    if (name !== '' && selectedOption !== '') {
      try {
        const orderDocRef = doc(db, 'orders', id);

        const data = {
          type: thisOrder.type,
          name,
          selectedOption,
          prince,
          inputs,
          addresses,
          checkbox,
        };

        await updateDoc(orderDocRef, data);

        const editData = order.map((od) => (od.id === id ? { ...od, ...data } : od));

        setOrders(editData);
      } catch (e) {
        console.error('Error updating document: ', e);
      }
    }
  };

  return (
    <article className="article_flex">
      <Header />
      <Center>
        <DashboardSt>
          <Title text="Editar serviços" url="order" icon={<FaPencil />} />

          {!thisOrder && (
            <h2>Carregando...</h2>
          )}

          {thisOrder && (
            <styles.OrderForm>
              <Link to="/order">
                <FaArrowLeft />
              </Link>
              <h2>
                Editar o serviço:
                <b>{name}</b>
              </h2>

              <styles.OrderFormGroup>
                <styles.OrderFormInput>
                  <span>Nome do pedido</span>
                  <input type="text" placeholder="Digite um nome" value={name} onChange={(e) => setName(e.target.value)} />
                </styles.OrderFormInput>

                <styles.OrderFormSelect>
                  <span>Selecione a coleção</span>
                  <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                    {thisOrder.type === 'order' ? (
                      <>
                        <option value="registro Civil">Registro Civil</option>
                        <option value="registro de Imóveis">Registro de Imóveis</option>
                        <option value="notas">Notas</option>
                        <option value="protesto">Protesto</option>
                        <option value="federais">Federais</option>
                        <option value="estaduais">Estaduais</option>
                        <option value="municipais">Municipais</option>
                      </>
                    ) : (
                      <>
                        <option value="pessoas-empresa">Pessoas/Empresa</option>
                        <option value="debitos-pendencias">Débitos/Pendências</option>
                        <option value="bens-moveis">Bens Móveis</option>
                        <option value="bens-imoveis">Bens Imóveis</option>
                      </>
                    )}
                  </select>
                </styles.OrderFormSelect>
              </styles.OrderFormGroup>

              <styles.OrderFormGroup>
                <styles.OrderFormInput>
                  <span>Valor do pedido</span>
                  <input type="number" placeholder="Adicione um valor" value={prince} onChange={(e) => setPrince(e.target.value)} />
                </styles.OrderFormInput>

                <styles.OrderFormCheckbox>
                  <span>Informações adicionais</span>
                  <input type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
                </styles.OrderFormCheckbox>
              </styles.OrderFormGroup>

              {checkbox && (
                <Inputs setInputs={setInputs} inputs={inputs} />
              )}

              <styles.OrderFormShow>
                <button type="button" onClick={() => setShow(!show)}>
                  {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
                  {' '}
                  Adicionar valores extras
                </button>
              </styles.OrderFormShow>

              {show && (
                <Value setAddresses={setAddresses} addresses={addresses} />
              )}

              <button type="button" className="button_submit" onClick={handleSubmit}>Editar</button>
            </styles.OrderForm>
          )}
        </DashboardSt>
      </Center>
    </article>
  );
}
