import styled from 'styled-components';

export const OrderSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OrderButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  
  a{
    margin-right: 25px;
  }
`;

export const MyRequestFilter = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundHover};
  border-radius: 0.3rem;
  border: 1px solid ${(props) => props.theme.textDark};
  padding: 10px 20px;
  margin-top: 15px;

  button{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 0;
    color: ${(props) => props.theme.textDark};
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;

    svg{
      font-size: 20px;
      margin-left: 10px;
    }
  }
`;

export const MyRequestFilterGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const MyRequestFilterInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 25px;

  span{
    font-size: 15px;
  }

  div{
    display: flex;
    align-items: center;    
  }

  input{
    margin-top: 7px;
    padding: 5px 10px;
    border-radius: 0.3rem;
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 15px;
  }

  input[type=radio], input[type=checkbox]{
    background-color: red;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 7px;
    width: 18px;
    height: 18px;

    &:nth-child(1){
      margin-left: 0px;
    }
  }
`;

export const OrderButton = styled.button`
  width: 100%;
  max-width: 230px;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.textLigth};
  border: 0;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.layout};
  border-radius: 0.5rem;
  padding: 10px 5px;
  margin-right: 20px;

  &:hover{
    background-color: ${(props) => props.theme.layoutHover};
  }

  svg{
    margin-right: 5px;
  }
  
`;

export const OrderTables = styled.table`
  width: 100%;
  margin-top: 30px;
  border: 1px solid ${(props) => props.theme.textDark};
  border-collapse: collapse;
  background-color: ${(props) => props.theme.backgroundHover};

  tbody tr:nth-child(odd){
    background-color: ${(props) => props.theme.background};
  }

  tr, td, th{
    border: 1px solid ${(props) => props.theme.textDark};
    font-size: 16px;
  }

  th{
    padding: 10px;
    font-weight: 600;
    text-align: center;
  }

  td{
    padding: 10px 7px;
    text-align: center;
    font-weight: 400;

    .table_edit{
      background-color: #D7A800;
      color: ${(props) => props.theme.textLigth};
      width: 32px;
      height: 32px;
      padding: 7px;
      cursor: pointer;
      border-radius: 0.1rem;
    }

    .table_close{
      background-color: #F5190A;
      color: ${(props) => props.theme.textLigth};
      width: 32px;
      height: 32px;
      padding: 2px;
      cursor: pointer;
      border-radius: 0.1rem;
    }
  }
`;

export const OrderClear = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;

  svg{
    font-size: 40px;
  }

  h3{
    font-size: 20px;
    font-weight: 600;
    margin-top: 8px;
  }

  span{
    max-width: 50%;
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    font-weight: 300;
  }
`;
