import React, { useEffect, useState } from 'react';
import { RiDraftLine } from 'react-icons/ri';
import { IoInformation } from 'react-icons/io5';
import { GrClear } from 'react-icons/gr';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import usePeople from '../../store/People';
import * as styles from '../Company/style';
import { MyRequestFilter, MyRequestFilterGroup, MyRequestFilterInput } from '../Order/style';

export default function Application() {
  const [show, setShow] = useState(false);
  const [statusFilters, setStatusFilters] = useState({
    pending: false,
    progress: false,
    finish: false,
  });
  const [searchAppl, setSearchAppl] = useState([]);
  const [numberIdSearch, setNumberIdSearch] = useState('');
  const [docSearch, setDocSearch] = useState('');
  const [dateSearch, setDateSearch] = useState('');
  const { application, fetchApplication } = usePeople();

  useEffect(() => {
    fetchApplication();
  }, []);

  useEffect(() => {
    const sortedAppl = [...application].sort((a, b) => b.numberId - a.numberId);
    let filteredAppl = sortedAppl;

    if (numberIdSearch.trim() !== '') {
      filteredAppl = filteredAppl.filter((appl) => appl.numberId.toString().includes(numberIdSearch));
    }

    if (docSearch.trim() !== '') {
      filteredAppl = filteredAppl.filter((appl) => appl.userDoc.toLowerCase().includes(docSearch.toLowerCase()));
    }

    if (dateSearch.trim() !== '') {
      filteredAppl = filteredAppl.filter((appl) => appl.dateFormat.includes(dateSearch));
    }

    const selectedStatuses = Object.keys(statusFilters).filter((status) => statusFilters[status]);
    if (selectedStatuses.length > 0) {
      filteredAppl = filteredAppl.filter((appl) => selectedStatuses.includes(appl.status));
    }

    setSearchAppl(filteredAppl);
  }, [numberIdSearch, docSearch, dateSearch, statusFilters, application]);

  useEffect(() => {
    console.log(application);
  }, [application]);

  const clearFilters = () => {
    setNumberIdSearch('');
    setDocSearch('');
    setDateSearch('');
    setStatusFilters({
      pending: false,
      progress: false,
      finish: false,
    });
  };

  const handleStatusChange = (status) => {
    setStatusFilters((prevStatusFilters) => ({
      ...prevStatusFilters,
      [status]: !prevStatusFilters[status],
    }));
  };

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      case 'finish':
        return 'table_access_true';

      default:
        return '';
    }
  };

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Controle de pedidos" url="application" icon={<RiDraftLine />} />

          <styles.CompanySearch>

            {application && (
              application.length > 0 && (
              <MyRequestFilter>
                <button type="button" onClick={() => setShow(!show)}>
                  Filtro
                  {show ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </button>

                {show && (
                <MyRequestFilterGroup>
                  <MyRequestFilterInput>
                    <span>Número do pedido</span>
                    <input type="text" name="numberId" placeholder="Digite o nº do pedido" value={numberIdSearch} onChange={(e) => setNumberIdSearch(e.target.value)} />
                  </MyRequestFilterInput>

                  <MyRequestFilterInput>
                    <span>Documento</span>
                    <input type="number" name="doc" placeholder="Digite o CPF/CNPJ" value={docSearch} onChange={(e) => setDocSearch(e.target.value)} />
                  </MyRequestFilterInput>

                  <MyRequestFilterInput>
                    <span>Data</span>
                    <input type="text" name="date" placeholder="Digite a data" value={dateSearch} onChange={(e) => setDateSearch(e.target.value)} />
                  </MyRequestFilterInput>

                  <MyRequestFilterInput>
                    <span>Filtrar por status</span>

                    <div>
                      <input type="checkbox" name="status" checked={statusFilters.pending} onChange={() => handleStatusChange('pending')} />
                      <span>Pendente</span>

                      <input type="checkbox" name="status" checked={statusFilters.progress} onChange={() => handleStatusChange('progress')} />
                      <span>Em andamento</span>

                      <input type="checkbox" name="status" checked={statusFilters.finish} onChange={() => handleStatusChange('finish')} />
                      <span>Finalizado</span>
                    </div>
                  </MyRequestFilterInput>

                </MyRequestFilterGroup>
                )}
              </MyRequestFilter>
              )
            )}
          </styles.CompanySearch>

          {searchAppl && searchAppl.length <= 0 && (
            <styles.CompanyClear>
              <GrClear />
              <h3>Nenhum pedido encontrado</h3>
              <span>Verifique os dados do filtro, clique no botão para limpar os filtros</span>
              <button type="button" onClick={clearFilters}>Limpar filtros</button>
            </styles.CompanyClear>
          )}

          {searchAppl && searchAppl.length > 0 && (
            <styles.ComapanyTables>
              <thead>
                <tr>
                  <th>Nº pedido</th>
                  <th>CPF/CNPJ</th>
                  <th>Nome do pedido</th>
                  <th>Data</th>
                  <th>Preço</th>
                  <th>Estado</th>
                  <th>Status</th>
                  <th>#</th>
                </tr>
              </thead>

              <tbody>
                {searchAppl && searchAppl.map((appl) => (
                  <tr key={appl.uid}>
                    <td>{appl.numberId}</td>
                    <td>{appl.userDoc}</td>
                    <td>{appl.nameOrder}</td>
                    <td>{appl.dateFormat}</td>
                    <td>
                      R$
                      {' '}
                      {parseFloat(appl.prince).toFixed(2).replace('.', ',')}
                    </td>
                    <td>{appl.state}</td>
                    <td>
                      <span className={changeCheck(appl.status)}>
                        {appl.status === 'pending' && 'Pendente'}
                        {appl.status === 'progress' && 'Em andamento'}
                        {appl.status === 'finish' && 'Finalizado'}
                      </span>
                    </td>
                    <td>
                      <Link to={`/application/${appl.uid}`} className="table_info">
                        {' '}
                        <IoInformation />
                        {' '}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </styles.ComapanyTables>
          )}

        </DashboardSt>
      </Center>
    </article>
  );
}
