import React, { useEffect, useState } from 'react';
import { IoClose, IoInformation } from 'react-icons/io5';
import * as styles from './style';
import usePeople from '../../store/People';

export default function CpfInfo({
  func, uid, setShow, setIdAppl,
}) {
  const [thisUser, setThisUser] = useState([]);
  const [thisAppl, setThisAppl] = useState([]);
  const { user, application } = usePeople();

  useEffect(() => {
    const us = user.filter((el) => el.uid === uid);
    const appl = application.filter((el) => el.userUid === uid);

    setThisUser(us);
    setThisAppl(appl);
    console.log(appl);
  }, [user, application]);

  const changeCheck = (status) => {
    switch (status) {
      case 'pending':
        return 'table_access_false';

      case 'progress':
        return 'table_access_progress';

      default:
        return 'table_access_true';
    }
  };

  return (
    <styles.FormOrderSt>

      <styles.FormOrderForm>
        <button type="button" className="close" onClick={func}><IoClose /></button>
        <h2>Informações do Cpf</h2>

        <styles.OrderInfo>
          {thisUser.map((el) => (
            <>
              <span>
                Nome:
                <b>
                  {el.name}
                </b>
              </span>
              <span>
                Email:
                <b>
                  {el.email}
                </b>
              </span>
              <span>
                Cpf:
                <b>
                  {el.cpf}
                </b>
              </span>
            </>
          ))}
        </styles.OrderInfo>

        <styles.OrderApplication>
          <h3>Pedidos recentes</h3>

          {thisAppl.map((appl) => (
            <styles.ApplicationList>

              <span>{appl.nameOrder}</span>
              <span>{appl.dateFormat}</span>
              <span className={changeCheck(appl.status)}>
                {appl.status === 'pending' ? 'Pendente' : ''}
                {appl.status === 'progress' ? 'Em andamento' : ''}
                {appl.status === 'finish' ? 'Finalizado' : ''}
              </span>
              <span>
                Categorias:
                <b>{appl.orderSelect.length}</b>
              </span>
              <button type="button">
                <IoInformation onClick={() => { setShow(true); setIdAppl(appl.uid); }} />
              </button>

            </styles.ApplicationList>
          ))}
        </styles.OrderApplication>

      </styles.FormOrderForm>
    </styles.FormOrderSt>
  );
}
