import styled from 'styled-components';

export const CompanyInfos = styled.section`
  display: flex;
  flex-direction: column;

  a{
    font-size: 22px;
    margin-bottom: 15px;
  }

  h4{
    margin-top: 15px;
    font-weight: 400;
    margin-bottom: -20px;
  }

  .table_info{
    background-color: #1A7DF4;
    color: ${(props) => props.theme.textLigth};
    width: 32px;
    height: 32px;
    padding: 2px;
    cursor: pointer;
    border-radius: 0.2rem;
  }
`;

export const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  h3{
    font-size: 18px;
    font-weight: 500;

    b{
      margin-left: 5px;
    }
  }
`;

export const CompanyAppl = styled.div`
  
`;
