import React, { useEffect, useState } from 'react';
import * as styles from './style';

export default function Value({ setAddresses, addresses }) {
  const [estados, setEstados] = useState([]);
  const [localAddresses, setLocalAddresses] = useState(addresses || []);

  useEffect(() => {
    // Fetch estados from IBGE API
    fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados')
      .then((response) => response.json())
      .then((data) => {
        setEstados(data);
      })
      .catch((error) => console.error('Error fetching estados:', error));
  }, []);

  const getCidades = (estadoSigla) => fetch(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${estadoSigla}/municipios`)
    .then((response) => response.json())
    .catch((error) => {
      console.error('Error fetching cidades:', error);
      return [];
    });

  const addAddress = () => {
    setLocalAddresses([...localAddresses, {
      estado: '', cidade: '', valor: '', cidades: [],
    }]);
  };

  const removeAddress = (index) => {
    const values = [...localAddresses];
    values.splice(index, 1);
    setLocalAddresses(values);
  };

  const handleAddressChange = async (index, event) => {
    const { name, value } = event.target;
    const values = [...localAddresses];
    values[index][name] = value;
    if (name === 'estado') {
      const cidades = await getCidades(value);
      values[index].cidade = ''; // Reset city when state changes
      values[index].cidades = cidades;
    }
    setLocalAddresses(values);
  };

  // Update parent addresses state when localAddresses changes
  useEffect(() => {
    setAddresses(localAddresses);
  }, [localAddresses, setAddresses]);

  return (
    <styles.ValueSt>
      {localAddresses && localAddresses.map((address, index) => (
        <styles.ValueGroup>
          <span>
            Estado:
            <select
              name="estado"
              value={address.estado}
              onChange={(event) => handleAddressChange(index, event)}
              required
            >
              <option value="">Selecione o Estado</option>
              {estados && estados.map((estado) => (
                <option key={estado.id} value={estado.sigla}>
                  {estado.nome}
                </option>
              ))}
            </select>
          </span>
          <span>
            Cidade:
            <select
              name="cidade"
              value={address.cidade}
              onChange={(event) => handleAddressChange(index, event)}
              required
              disabled={!address.estado}
            >
              <option value="">Selecione a Cidade</option>
              {address.estado && address.cidades && address.cidades.map((cidade) => (
                <option key={cidade.id} value={cidade.nome}>
                  {cidade.nome}
                </option>
              ))}
            </select>
          </span>
          <span>
            Valor:
            <input
              type="number"
              name="valor"
              value={address.valor}
              onChange={(event) => handleAddressChange(index, event)}
              required
            />
          </span>
          <button
            type="button"
            onClick={() => removeAddress(index)}
          >
            Remover
          </button>
        </styles.ValueGroup>
      ))}
      <button type="button" onClick={addAddress} className="addButton">
        Adicionar Mais
      </button>
    </styles.ValueSt>
  );
}
