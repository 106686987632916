import { create } from 'zustand';
import { signOut } from 'firebase/auth';
import { auth } from '../service/firebaseConnection';

const useUser = create((set) => ({
  users: [],

  storageUser: (data) => {
    localStorage.setItem('@JK-admin', JSON.stringify(data));
    set({ storage: data });
  },

  setLoading: (boolean = false) => {
    set({ loading: boolean });
  },

  logoutUser: async () => {
    await signOut(auth);
    localStorage.removeItem('@JK-admin');
    set({ user: null, storage: null });
  },
}));

export default useUser;
