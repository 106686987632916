import styled from 'styled-components';

export const FormOrderSt = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

export const FormOrderForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.background};

  h2{
    font-size: 22px;
    margin-top: 15px;
    text-align: center;
  }

  .progress{
    display: flex;
    align-items: center;
    margin-top: 30px;

    p{
      margin-left: 5px;
    }

    input{
    appearance: none;
    width: 50%;
    height: 20px;
    background: ${(props) => props.theme.layout};
    border-radius: 0.2rem;
    padding: 1px;
    outline: none;
    opacity: 1;
    transition: opacity .15s ease-in-out;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 16px;
      height: 16px;
      background: ${(props) => props.theme.backgroundHover};
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      background: ${(props) => props.theme.backgroundHover};
      border-radius: 50%;
      cursor: pointer;
    }}
  }

  .close{
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    padding: 10px;
    font-size: 30px;
    background-color: transparent;
    border: 0;
  }

  .button_submit{
    max-width: 230px;
    margin-top: 40px;
    background-color: ${(props) => props.theme.layout};
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 18px;
    color: ${(props) => props.theme.textLigth};
    padding: 10px;

    &:hover{
      background-color: ${(props) => props.theme.layoutHover};
    }
  }
`;

export const OrderFormGroup = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OrderFormInput = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  input{
    width: 100%;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
  }
`;

export const OrderFormSelect = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  select{
    width: 100%;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    
    option{
      cursor: pointer;
      
    }
  }
`;

export const OrderFormCheckbox = styled.div`
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  input{
    display: block;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-color: blue;
    margin-top: 5px;
  }
`;

export const OrderList = styled.ul`
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 25px;

  li{
    font-size: 16px;
    margin-top: 15px;

    b{
      margin-left: 5px;
    }
  }
`;

export const OrderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  margin-top: 25px;

  span{
    font-size: 16px;

    b{
      margin-left: 5px;
    }
  }
`;

export const OrderApplication = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: column;

  h3{
    font-size: 18px;
    font-weight: 500;
  }
`;

export const OrderSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;

  button{
    font-size: 16px;
    border: 1px solid ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textDark};
    border-radius: 0.5rem;
    background-color: transparent;
    padding: 5px 15px;
    margin-right: 15px;
    cursor: pointer;
  }

  .btn_select{
    background-color: ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textLigth};
  }
`;

export const ApplicationList = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 5px 20px;
  border: 1px solid ${(props) => props.theme.textDark};
  margin-top: 15px;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-around;

  span{
    font-size: 16px;
    border-radius: 0.5rem;
    padding: 5px 10px;
  }

  button{
    display: flex;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    padding: 2px;
    border-radius: 0.2rem;
    border: 0;
    background-color: #1A7DF4;
    color: ${(props) => props.theme.textLigth};
  }

  .table_access_false{
    color: ${(props) => props.theme.textLigth};
    background-color: #F5190A;
  }

  .table_access_true{
    color: ${(props) => props.theme.textLigth};
    background-color: #01F510;
  }
  
  .table_access_progress{
    color: ${(props) => props.theme.textLigth};
    background-color: #D7A800;
  }
`;

export const ApplicationListInfo = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.backgroundHover};
  padding: 5px 20px;
  border: 1px solid ${(props) => props.theme.textDark};
  margin-top: 15px;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-around;

  span{
    font-size: 16px;
    margin-right: 10px;
  }

  div{
    position: relative;
    height: 100%;

    input{
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      opacity: 0;
      cursor: pointer !important;
      width: 100%;
      height: 100%;
      
    }

    p{
      
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 14px;
      padding: 8px 15px;
      border-radius: 0.2rem;
      cursor: pointer;
      border: 0;
      background-color: ${(props) => props.theme.textDark};
      color: ${(props) => props.theme.textLigth};

      svg{
        margin-right: 5px;
      }

      &:hover{
        transition: 0.7s;
        opacity: 0.9;
      }
    }
  }

  h3{
    font-size: 16px;
    font-weight: 400;
    padding: 5px 8px;
    border-radius: 0.5rem;
    color: ${(props) => props.theme.textLigth};
  }

  .table_access_false{
    background-color: #F5190A;
  }

  .table_access_true{
    background-color: #01F510;
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 0.2rem;
    cursor: pointer;
    border: 0;
    background-color: ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textLigth};

    svg{
      margin-right: 5px;
    }

    &:hover{
      transition: 0.7s;
      opacity: 0.9;
    }
  }

  .btn_view{
    background-color: transparent;
    color: ${(props) => props.theme.textDark};
    font-weight: 500;
    padding: 3px;
    margin-left: 20px;

    svg{
      font-size: 22px;
    }
  }
`;

export const ApplicationInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  span{
    font-size: 18px;
    margin-right: 10px;
  }

  button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 0.2rem;
    cursor: pointer;
    border: 0;
    background-color: ${(props) => props.theme.textDark};
    color: ${(props) => props.theme.textLigth};

    svg{
      margin-right: 5px;
    }

    &:hover{
      transition: 0.7s;
      opacity: 0.9;
    }
  }

`;
