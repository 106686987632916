import styled from 'styled-components';

export const SignSt = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  h3{
    font-size: 30px;

  }
`;

export const SignForm = styled.form`
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  color: ${(props) => props.theme.text};

  span{
    width: 100%;
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-align: start;
  }

  input{
    width: 100%;
    font-size: 16px;
    padding: 5px;
  }

  button{
    margin-top: 20px;
    width: 100%;
    padding: 8px;
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 400;
    background-color: ${(props) => props.theme.layout};
    color: ${(props) => props.theme.textLigth};
    border: 1px solid ${(props) => props.theme.text};
  }
`;
