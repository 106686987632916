import React, { useState } from 'react';
import { FaArrowLeft, FaPencil } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { addDoc, collection } from 'firebase/firestore';
import Header from '../../components/Header';
import Center from '../../components/Center';
import { DashboardSt } from '../Dashboard/style';
import Title from '../../components/Title';
import * as styles from '../OrderEdit/style';
import Inputs from '../../components/Inputs';
import Value from '../../components/Value';
import { db } from '../../service/firebaseConnection';

export default function Search() {
  const initialAddressFields = {
    estado: '',
    cidade: '',
    valor: '',
  };

  const initialFields = {
    nomeMae: false,
    nomePai: false,
    dataNascimento: false,
    naturalidade: false,
    genero: false,
    dataAbertura: false,
    numeroNire: false,
    imovel: false,
    numeroMatricula: false,
    numeroTranscricao: false,
    numeroRegistro: false,
    enderecoCompleto: false,
    matriculaMae: false,
    notas: false,
    livro: false,
    folha: false,
    dataLavratura: false,
    civil: false,
    livroCivil: false,
    folhaCivil: false,
    dataRegistroCivil: false,
    numeroItr: false,
    numeroRip: false,
    numeroIe: false,
    municipais: false,
    numeroIptu: false,
  };

  const [addresses, setAddresses] = useState([initialAddressFields]);
  const [inputs, setInputs] = useState([initialFields]);
  const [name, setName] = useState('');
  const [prince, setPrince] = useState(0);
  const [selectedOption, setSelectedOption] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [show, setShow] = useState(false);

  const submitOrder = async () => {
    if (name !== '' && selectedOption !== '' && prince !== 0) {
      try {
        const data = {
          type: 'search',
          name,
          selectedOption,
          prince,
          inputs,
          addresses,
          checkbox,
        };

        await addDoc(collection(db, 'orders'), data);

        setName('');
        setSelectedOption('');
        setPrince(0);
        setInputs(initialFields);
        setAddresses();
        setCheckbox(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <article className="article_flex">

      <Header />
      <Center>
        <DashboardSt>
          <Title text="Cadastrar pesquisa" url="search" icon={<FaPencil />} />

          <styles.OrderForm>

            <Link to="/order">
              <FaArrowLeft />
            </Link>

            <styles.OrderFormGroup>
              <styles.OrderFormInput>
                <span>Nome do pedido</span>
                <input type="text" placeholder="Digite um nome" value={name} onChange={(e) => setName(e.target.value)} />
              </styles.OrderFormInput>

              <styles.OrderFormSelect>
                <span>Selecione a coleção</span>

                <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
                  <option selected>Escolha a coleção</option>
                  <option value="pessoas-empresa">Pessoas/Empresa</option>
                  <option value="debitos-pendencias">Débitos/Pendências</option>
                  <option value="bens-moveis">Bens Móveis</option>
                  <option value="bens-imoveis">Bens Imóveis</option>
                </select>
              </styles.OrderFormSelect>
            </styles.OrderFormGroup>

            <styles.OrderFormGroup>
              <styles.OrderFormInput>
                <span>Valor do pedido</span>
                <input type="number" placeholder="Adicione um valor" value={prince} onChange={(e) => setPrince(e.target.value)} />
              </styles.OrderFormInput>

              <styles.OrderFormCheckbox>
                <span>Informações adicionais</span>
                <input type="checkbox" checked={checkbox} onChange={(e) => setCheckbox(e.target.checked)} />
              </styles.OrderFormCheckbox>
            </styles.OrderFormGroup>

            {checkbox && (
            <Inputs setInputs={setInputs} inputs={inputs} />
            )}

            <styles.OrderFormShow>
              <button type="button" onClick={() => setShow(!show)}>
                {show ? <IoIosArrowUp /> : <IoIosArrowDown />}
                {' '}
                Adicionar valores extras
              </button>
            </styles.OrderFormShow>

            {show && (
            <Value setAddresses={setAddresses} addresses={addresses} />
            )}

            <button type="button" className="button_submit" onClick={submitOrder}>Cadastrar</button>

          </styles.OrderForm>

        </DashboardSt>
      </Center>
    </article>
  );
}
