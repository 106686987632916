import React, { useEffect } from 'react';
import { FiHome } from 'react-icons/fi';
import * as styles from './style';
import Center from '../../components/Center';
import Header from '../../components/Header';
import Title from '../../components/Title';
import usePeople from '../../store/People';

export default function Dashboard() {
  const { fetchApplication } = usePeople();

  useEffect(() => {
    fetchApplication();
  }, []);

  return (

    <article className="article_flex">

      <Header />
      <Center>
        <styles.DashboardSt>
          <Title text="Painel de controle" icon={<FiHome />} />

          <styles.DashboardGroup>
            <styles.DashboardInfos className="box_shadow">
              <h3>
                Usuários onlines:
                <b>30</b>
              </h3>
            </styles.DashboardInfos>

            <styles.DashboardInfos className="box_shadow">
              <h3>
                Usuários onlines hoje:
                <b>30</b>
              </h3>
            </styles.DashboardInfos>

            <styles.DashboardInfos className="box_shadow">
              <h3>
                Usuários onlines esse mês:
                <b>30</b>
              </h3>
            </styles.DashboardInfos>
          </styles.DashboardGroup>

        </styles.DashboardSt>
      </Center>
    </article>
  );
}
