import React from 'react';
import * as styles from './style';

export default function Inputs({ setInputs, inputs }) {
  const handleChange = (index, event) => {
    const values = [...inputs];
    values[index][event.target.name] = event.target.checked;
    setInputs(values);
  };

  return (
    <styles.InputsSt>
      {inputs.map((input, index) => (
        <styles.InputGroup>

          <div>

            <span>
              Nome da Mãe
              <input
                type="checkbox"
                name="nomeMae"
                checked={input.nomeMae}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Nome do Pai:
              <input
                type="checkbox"
                name="nomePai"
                checked={input.nomePai}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Data de Nascimento:
              <input
                type="checkbox"
                name="dataNascimento"
                checked={input.dataNascimento}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Naturalidade:
              <input
                type="checkbox"
                name="naturalidade"
                checked={input.naturalidade}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Gênero:
              <input
                type="checkbox"
                name="genero"
                checked={input.genero}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              CNPJ:
              <input
                type="checkbox"
                name="cnpj"
                checked={input.cnpj}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Data de Abertura:
              <input
                type="checkbox"
                name="dataAbertura"
                checked={input.dataAbertura}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Número do NIRE:
              <input
                type="checkbox"
                name="numeroNire"
                checked={input.numeroNire}
                onChange={(event) => handleChange(index, event)}
              />
            </span>

            <span>
              Imóvel:
              <input
                type="checkbox"
                name="imovel"
                checked={input.imovel}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Número de Matrícula:
              <input
                type="checkbox"
                name="numeroMatricula"
                checked={input.numeroMatricula}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
          </div>
          <div>
            <span>
              Número de Transcrição:
              <input
                type="checkbox"
                name="numeroTranscricao"
                checked={input.numeroTranscricao}
                onChange={(event) => handleChange(index, event)}
              />
            </span>

            <span>
              Número do Registro:
              <input
                type="checkbox"
                name="numeroRegistro"
                checked={input.numeroRegistro}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Endereço Completo:
              <input
                type="checkbox"
                name="enderecoCompleto"
                checked={input.enderecoCompleto}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Matrícula Mãe:
              <input
                type="checkbox"
                name="matriculaMae"
                checked={input.matriculaMae}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Notas:
              <input
                type="checkbox"
                name="notas"
                checked={input.notas}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Livro:
              <input
                type="checkbox"
                name="livro"
                checked={input.livro}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Folha:
              <input
                type="checkbox"
                name="folha"
                checked={input.folha}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Data de Lavratura:
              <input
                type="checkbox"
                name="dataLavratura"
                checked={input.dataLavratura}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Civil:
              <input
                type="checkbox"
                name="civil"
                checked={input.civil}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Livro (Civil):
              <input
                type="checkbox"
                name="livroCivil"
                checked={input.livroCivil}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
          </div>
          <div>
            <span>
              Folha (Civil):
              <input
                type="checkbox"
                name="folhaCivil"
                checked={input.folhaCivil}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Data do Registro (Civil):
              <input
                type="checkbox"
                name="dataRegistroCivil"
                checked={input.dataRegistroCivil}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Federais:
              <input
                type="checkbox"
                name="federais"
                checked={input.federais}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Número do ITR (NIRF):
              <input
                type="checkbox"
                name="numeroItr"
                checked={input.numeroItr}
                onChange={(event) => handleChange(index, event)}
              />
            </span>

            <span>
              Número do RIP:
              <input
                type="checkbox"
                name="numeroRip"
                checked={input.numeroRip}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Estaduais:
              <input
                type="checkbox"
                name="estaduais"
                checked={input.estaduais}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Número da IE (Inscrição Estadual):
              <input
                type="checkbox"
                name="numeroIe"
                checked={input.numeroIe}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Municipais:
              <input
                type="checkbox"
                name="municipais"
                checked={input.municipais}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
            <span>
              Número do Cadastro de IPTU:
              <input
                type="checkbox"
                name="numeroIptu"
                checked={input.numeroIptu}
                onChange={(event) => handleChange(index, event)}
              />
            </span>
          </div>
        </styles.InputGroup>
      ))}
    </styles.InputsSt>
  );
}
