import styled from 'styled-components';

export const FormOrderSt = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9;
`;

export const FormOrderForm = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  border-radius: 0.5rem;
  background-color: ${(props) => props.theme.background};

  h2{
    font-size: 22px;
    margin-top: 15px;
    text-align: center;
  }

  .close{
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    padding: 10px;
    font-size: 30px;
    background-color: transparent;
    border: 0;
  }

  .button_submit{
    max-width: 230px;
    margin-top: 40px;
    background-color: ${(props) => props.theme.layout};
    border: none;
    cursor: pointer;
    border-radius: 0.5rem;
    font-size: 18px;
    color: ${(props) => props.theme.textLigth};
    padding: 10px;

    &:hover{
      background-color: ${(props) => props.theme.layoutHover};
    }
  }
`;

export const OrderFormGroup = styled.div`
  width: 100%;
  margin-top: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OrderFormInput = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  input{
    width: 100%;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
  }
`;

export const OrderFormSelect = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  select{
    width: 100%;
    font-size: 16px;
    padding: 5px;
    margin-top: 5px;
    cursor: pointer;
    
    option{
      cursor: pointer;
      
    }
  }
`;

export const OrderFormCheckbox = styled.div`
  width: 30%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  input{
    display: block;
    width: 18px;
    height: 18px;
    cursor: pointer;
    background-color: blue;
    margin-top: 5px;
  }
`;

export const OrderFormShow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  button{
    background-color: transparent;
    border: 0;
    color: ${(props) => props.theme.textDark};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    margin-top: 15px;
    cursor: pointer;

    svg{
      font-size: 18px;
      margin-bottom: -3px;
      margin-right: 5px;
    }
  }
`;
